let initializing = false;
let initialized = false;

const initCallbacks = [];

export function initAppleMapKit(callback) {
  if (initialized) {
    callback();
    return;
  }

  initCallbacks.push(callback);

  // The AppleMapKit is already being initialized somewhere else, just wait.
  if (initializing) {
    return;
  }

  initializing = true;

  mapkit.init({
    language: 'fr',
    authorizationCallback(done) {
      fetch('/get-mapkit-token') // route name = get_apple_mapkit_token
        .then((res) => res.json())
        .then((json) => {
          done(json.token);

          initializing = false;
          initialized = true;

          initCallbacks.forEach((cb) => cb());
        });
    },
  });
}

export function initAppleMap(
  element,
  { zoom = 10, latCenter = 46.86178015, lngCenter = 1.70158805, type = mapkit.Map.MapTypes.Standard } = {}
) {
  const options = {
    center: new mapkit.Coordinate(latCenter, lngCenter),
    mapType: type,
  };

  const map = new mapkit.Map(element, options);

  // eslint-disable-next-line no-use-before-define
  setAppleMapZoom(map, { zoom });

  return map;
}

export function setAppleMapZoom(map, { zoom }) {
  // eslint-disable-next-line no-param-reassign,no-underscore-dangle
  map._impl.zoomLevel = zoom;
  map.setCenterAnimated(map.center, true);
}

export function setAppleMapCenter(map, { lat, lng }) {
  map.setCenterAnimated(new mapkit.Coordinate(lat, lng));
}

export function createAppleMapMarker(map, { lat, lng, callout, icon }) {
  const coordinates = new mapkit.Coordinate(Number(lat), Number(lng));
  let annotation;

  const commonAnnotationConfig = {
    callout,
  };

  if (icon) {
    annotation = new mapkit.ImageAnnotation(coordinates, {
      ...commonAnnotationConfig,
      url: { 1: icon },
    });
  } else {
    annotation = new mapkit.MarkerAnnotation(coordinates, {
      ...commonAnnotationConfig,
    });
  }

  map.addAnnotation(annotation);
}

export function setAppleMapStyles(map, { styles } = {}) {
  map.setOptions({ styles: JSON.parse(styles) });
}

export function addAppleMapCircle(
  map,
  { lat, lng, radius, strokeColor, strokeOpacity, strokeWeight, fillColor, fillOpacity }
) {
  const style = new mapkit.Style({
    strokeColor,
    strokeOpacity,
    lineWidth: strokeWeight,
    fillColor,
    fillOpacity,
  });

  const coordinates = new mapkit.Coordinate(Number(lat), Number(lng));
  const circleOverlay = new mapkit.CircleOverlay(coordinates, radius, {
    style,
  });

  map.addOverlay(circleOverlay);
}
