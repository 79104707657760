import {
  addAppleMapCircle,
  createAppleMapMarker,
  initAppleMap,
  initAppleMapKit,
  setAppleMapCenter,
  setAppleMapStyles,
  setAppleMapZoom,
} from '../plymouth/js/apple-map-kit';

global.AppleMapKitUtils = {
  init: initAppleMapKit,
  initMap: initAppleMap,
  setZoom: setAppleMapZoom,
  setCenter: setAppleMapCenter,
  createMarker: createAppleMapMarker,
  setStyles: setAppleMapStyles,
  addCircle: addAppleMapCircle,
};
